import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

interface StyleProps {
  isRounded: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.color.text.onColor,
  },
  xxsmall: {
    width: theme.sizing[8],
    height: theme.sizing[8],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.xsmall : ''),
    ...theme.typography.label.smallBold,
    '& .online': {
      position: 'relative',
      width: 15,
      height: 15,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 22,
      marginTop: -12,
    },
  },
  xsmall: {
    width: theme.sizing[10],
    height: theme.sizing[10],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.xsmall : ''),
    ...theme.typography.headline.headline5,
    '& .online': {
      position: 'relative',
      width: 15,
      height: 15,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 27,
      marginTop: -12,
    },
  },
  small: {
    width: theme.sizing[12],
    height: theme.sizing[12],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline4,
    '& .online': {
      position: 'relative',
      width: 15,
      height: 15,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 35,
      marginTop: -12,
    },
  },
  medium: {
    width: theme.sizing[14],
    height: theme.sizing[14],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline3,
    '& .online': {
      position: 'relative',
      width: 18,
      height: 18,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 42,
      marginTop: -14,
    },
  },
  large: {
    width: theme.sizing[18],
    height: theme.sizing[18],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline2,
    '& .online': {
      position: 'relative',
      width: 18,
      height: 18,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 57,
      marginTop: -14,
    },
  },
  xlarge: {
    width: theme.sizing[20],
    height: theme.sizing[20],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.medium : ''),
    ...theme.typography.headline.headline2,
    '& .online': {
      position: 'relative',
      width: 20,
      height: 20,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 63,
      marginTop: -16,
    },
  },
  xxlarge: {
    width: theme.sizing[24],
    height: theme.sizing[24],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.medium : ''),
    ...theme.typography.headline.headline1,
    '& .online': {
      position: 'relative',
      width: 24,
      height: 24,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 75,
      marginTop: -20,
    },
  },
  xxxlarge: {
    width: theme.sizing[40],
    height: theme.sizing[40],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.large : ''),
    ...theme.typography.display.display2,
    '& .online': {
      position: 'relative',
      width: 26,
      height: 26,
      backgroundColor: '#F9911D',
      border: '3px solid white',
      borderRadius: '50%',
      marginLeft: 136,
      marginTop: -23,
    },
  },
}));

export enum AvatarSize {
  XXSMALL = 'xxsmall', // default
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  XXXLARGE = 'xxxlarge',
}

export enum AvatarShape {
  ROUNDED = 'rounded', // default
  SQUARE = 'square',
  CIRCULAR = 'circular',
}

export enum AvatarType {
  IMAGE = 'image', // default
  // PLACEHOLDER = 'placeholder',
  INITIALS = 'initials',
}

export enum AvatarColor {
  IMAGE = 'image', // default
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INDIGO = 'indigo',
  ORANGE = 'orange',
  LIME = 'lime',
  NEUTRAL = 'neutral',
}

export interface IAvatarProps {
  src?: string;
  name?: string;
  type?: AvatarType;
  shape?: AvatarShape;
  size?: AvatarSize;
  color?: any;
  className?: string;
  // border?: boolean; // default=false
  // badge?: boolean; // defailt=false
  // isOnline?: boolean; // defailt=false
  isChat?: boolean;
  isOnline?: boolean;
  [prop: string]: any;
}

type JWT = {
  email?: string;
  authorities: Array<string>;
  exp: number;
  iat: number;
  sub: string;
};

export const parseJwt = (token: string): JWT => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getUserAuthority = (token: string): string => {
  return parseJwt(token).authorities[0].replace('ROLE_', '');
};

export const getUserInfo = () => {
  const userInfo = localStorage.getItem('userInfo');
  return userInfo ? JSON.parse(userInfo) : {};
};

export const convertByteToString = (byte: number): string => {
  if (byte < 1024) {
    return `${byte}B`;
  }
  if (byte < 1024 * 1024) {
    return `${(byte / 1024).toFixed(2)} KB`;
  }
  return `${(byte / 1024 / 1024).toFixed(2)} MB`;
};

export const stringToColor = (string = '') => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name = '') => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      name?.split(' ').filter(i => i !== '')?.length > 1
        ? `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`
        : name?.[0],
  };
};

export const getFullName = (firstName, lastName, fullName) => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  return fullName ?? '';
};

export const getCurrencyMoney = (value: number, decimal?: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: decimal ?? 2,
  }).format(value);

export const getDurationUnit = (duration: number, minimize?: boolean) => {
  const minutesText = minimize ? 'min' : 'minutes';
  const hoursText = minimize ? 'hr' : 'hour';
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours === 0) return `${minutes} ${minutesText}`;
  if (minutes === 0) return `${hours} ${hoursText}`;
  return `${hours} ${hoursText} ${minutes} ${minutesText}`;
};

// convert minutes to hours and minutes
export const convertMinutesToHours = (minutes: number): string => {
  if (minutes < 60) {
    return `${minutes} minutes`;
  }

  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${
    minutesLeft > 0 ? `${minutesLeft} ${minutesLeft > 1 ? 'minutes' : 'minute'}` : ''
  }`;
};

// convert seconds to HH:MM:SS
export const convertSecondsToTime = (second: number): string => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second - hours * 3600) / 60);
  const seconds = second - hours * 3600 - minutes * 60;
  const mmss = `${minutes > 9 ? '' : 0}${minutes}:${seconds > 9 ? '' : 0}${seconds}`;

  if (hours) {
    return `${hours > 9 ? '' : 0}${hours}:${mmss}`;
  }
  return mmss;
};

export const compactMoney = (value_: number | undefined) => {
  if (value_ === undefined) return;
  const money = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    currencyDisplay: 'narrowSymbol',
  });

  return money.format(value_);
};

export const formatPhoneNumber = number => {
  if (number && number.length > 7) {
    number = number.substring(1);
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
  }
  return number;
};

export const triggerNewAppointmentFlow = (selectedProviderId, appointment, memberId) => {
  const extraParam: string[] = [];
  if (selectedProviderId) {
    extraParam.push(`selectedProvider=${selectedProviderId}`);
  }
  if (appointment && appointment.appointmentId && appointment.patientId) {
    extraParam.push(`appointmentId=${appointment.appointmentId}&memberId=${appointment.patientId}`);
  }
  if (memberId) {
    extraParam.push(`memberId=${appointment.patientId}`);
  }
  window.open(`/public/book-appointment${extraParam.length > 0 ? `?${extraParam.join('&')}` : ''}`, '_blank');
};

export const toSentenceCase = (text: string) => {
  if (!text) return '-';
  const spacedText = text?.replace('_', ' ').toLocaleLowerCase();
  return spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
};

export const isUserOnline = (onlineUsers, cId) => {
  const status = onlineUsers.includes(cId);
  return !!status;
};

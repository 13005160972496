import { FC } from 'react';
// Alfie Lottie icon to use in empty chat placement
import AlfieWithHeartBubble from '../lotties/lottieIcons/AlfieWithHeartBubble';
// payment methods
import Amazon from './Amazon';
import ApplePay from './ApplePay';
import GooglePay from './GooglePay';
import Mastercard from './Mastercard';
import Payoneer from './Payoneer';
import PayPal from './PayPal';
import Visa from './Visa';
import Hippa from './Hippa';
import InsuranceBlueCircle from './InsuranceBlueCircle';
import OnlyCashPayIcon from './OnlyCashPayIcon';
import CustomFilterIcon from './CustomFilter';
import Connecticut from './Connecticut';
import Florida from './Florida';
import NewHampshire from './NewHampshire';
import Texas from './Texas';
import Virginia from './Virginia';
import Upload from './Upload';
import ChatSquare from './ChatSquare';
import MembersOutlined from './MembersOutlined';
import RecordIcon from './RecordIcon';
import ExclamationCircleFilled from './ExclamationCircleFilled';
import Analytics from './Analytics';
import Edit from './Edit';
import IdCard from './IdCard';
import RunningLate from './RunningLate';
import Wallet from './Wallet';

const glyphs = {
  amazon: Amazon,
  'apple-pay': ApplePay,
  'google-pay': GooglePay,
  mastercard: Mastercard,
  payoneer: Payoneer,
  paypal: PayPal,
  visa: Visa,
  hipaa: Hippa,
  'insurance-blue-circle': InsuranceBlueCircle,
  'only-cash-pay': OnlyCashPayIcon,
  'custom-filter': CustomFilterIcon,
  connecticut: Connecticut,
  'new-hampshire': NewHampshire,
  texas: Texas,
  virginia: Virginia,
  florida: Florida,
  upload: Upload,
  'chat-square': ChatSquare,
  'alfie-with-heart-bubble': AlfieWithHeartBubble,
  'members-outlined': MembersOutlined,
  record: RecordIcon,
  'exclamation-circle-filled': ExclamationCircleFilled,
  analytics: Analytics,
  edit: Edit,
  idCard: IdCard,
  'running-late': RunningLate,
  wallet: Wallet,
};

export interface IGlyphs {
  glyph: keyof typeof glyphs;
}

export interface ISvgIconProps {
  className?: string;
  color?: string;
  glyph: IGlyphs['glyph'] | string;
  onClick?: (...args: any[]) => any;
}

export const SvgGlyphs = Object.keys(glyphs) as Array<IGlyphs['glyph']>;

const SvgIcons: FC<ISvgIconProps> = ({ className = '', color = 'none', glyph, onClick }) => {
  const Glyph = glyphs[glyph];
  if (Glyph) {
    return <Glyph className={className} color={color} onClick={onClick} />;
  }
  return null;
};

export { SvgIcons };

import { FC } from 'react';
import MuiAvatar from '@mui/material/Avatar';
import cx from 'clsx';

// utils
import { stringAvatar } from 'utils';

// constants
import { S3_BUCKET_URL } from 'constants/index';
import { bgColors } from './Avatar.constants';

// styles
import { useStyles } from './Avatar.styles';
import { IAvatarProps, AvatarColor, AvatarSize, AvatarShape } from './Avatar.types';
import { Box } from '@mui/material';

const Avatar: FC<IAvatarProps> = ({
  src = '',
  name = '',
  shape = AvatarShape.ROUNDED,
  size = AvatarSize.XXSMALL,
  color = AvatarColor.IMAGE,
  className = '',
  isChat = false,
  isOnline = false,
  ...rest
}) => {
  const classes = useStyles({ isRounded: shape === AvatarShape.ROUNDED });

  const url = src ? (src.includes('http') ? src : `${S3_BUCKET_URL}${src}`) : '';
  const customStyles = url
    ? { sx: { bgcolor: bgColors[color] } }
    : { ...stringAvatar(name || rest.alt), sx: { bgcolor: bgColors[color] } };

  return (
    <Box
      className={cx({
        [classes.root]: true,
        [classes.xxsmall]: size === AvatarSize.XXSMALL,
        [classes.xsmall]: size === AvatarSize.XSMALL,
        [classes.small]: size === AvatarSize.SMALL,
        [classes.medium]: size === AvatarSize.MEDIUM,
        [classes.large]: size === AvatarSize.LARGE,
        [classes.xlarge]: size === AvatarSize.XLARGE,
        [classes.xxlarge]: size === AvatarSize.XXLARGE,
        [classes.xxxlarge]: size === AvatarSize.XXXLARGE,
        [className]: className,
      })}
    >
      <MuiAvatar
        {...customStyles}
        className={cx({
          [classes.root]: true,
          [classes.xxsmall]: size === AvatarSize.XXSMALL,
          [classes.xsmall]: size === AvatarSize.XSMALL,
          [classes.small]: size === AvatarSize.SMALL,
          [classes.medium]: size === AvatarSize.MEDIUM,
          [classes.large]: size === AvatarSize.LARGE,
          [classes.xlarge]: size === AvatarSize.XLARGE,
          [classes.xxlarge]: size === AvatarSize.XXLARGE,
          [classes.xxxlarge]: size === AvatarSize.XXXLARGE,
          [className]: className,
        })}
        src={url}
        variant={shape}
        {...rest}
      />
      {isChat && <Box className="online" style={{ backgroundColor: isOnline ? '#51A365' : '#7d7d7d' }}></Box>}
    </Box>
  );
};

export { Avatar };
